import React from "react";
import { useState, useRef } from "react";
import axios from "axios";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "./components/Header";
import { Questions } from "./components/pages/Questions";
import { Surveys } from "./components/pages/Surveys";
import { SurveysV2 } from "./components/pages/SurveysV2";
import { UserDetails } from "./components/pages/UserDetails";
import GlobalStyle from "./components/GlobalStyle";
import { SubscriptionList } from "./components/pages/SubscriptionList";
import { AdminTools } from "./components/pages/AdminTools";
import { Bootcamps } from "./components/pages/Bootcamps";
import { Course } from "./components/pages/Course";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [message, setMessage] = useState<string>("");

  function handleLogin(
    username: string | undefined,
    password: string | undefined
  ) {
    const url = `${process.env.REACT_APP_NOT_SECRET_CM_URL}/sitecore/api/ssc/auth/login?sc_apikey=4B0DBFD0-D7B1-4FB4-8B72-D48EFEB66FDE
`;
    console.log(url);

    try {
      axios({
        method: "Post",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        url: url,
        data: {
          domain: "sitecore",
          username: username,
          password: password,
        },
      }).then((response) => {
        console.log(response.data);

        if (response.status === 200) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      });
    } catch (error) {
      setMessage(`Unable to login`);
      setLoggedIn(false);
    }
  }

  return (
    <div className="App">
      <GlobalStyle />
      <BrowserRouter>
        <Header />
        <main>
          {!loggedIn && (
            <>
              <Login onLoginClick={handleLogin} />
              {message && <p>{message}</p>}
            </>
          )}
          {loggedIn && (
            <Routes>
              <Route path="/questions/:pmoId" element={<Questions />} />
              <Route path="/questions" element={<Questions />} />
              <Route path="/surveys/:pmoId" element={<Surveys />} />
              <Route path="/surveys/v1" element={<Surveys />} />
              <Route path="/surveys" element={<SurveysV2 />} />
              <Route path="/users/:pmoId" element={<UserDetails />} />
              <Route path="/users" element={<UserDetails />} />
              <Route path="/admin" element={<AdminTools />} />
              <Route path="/bootcamps" element={<Bootcamps />} />
              <Route path="/bootcamps/:bootcampId/" element={<Bootcamps />} />
              <Route path="/bootcamps/:bootcampId/:courseId" element={<Course />} />
              <Route path="/" element={<SubscriptionList />} />
            </Routes>
          )}
        </main>
      </BrowserRouter>
    </div>
  );
}

interface LoginProps {
  onLoginClick: (
    username: string | undefined,
    password: string | undefined
  ) => void;
}

const Login: React.FC<LoginProps> = ({ onLoginClick }) => {
  const userNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onLoginClick(userNameRef.current?.value, passwordRef.current?.value);
  };

  return (
    <div className="pl-5">
      <h1>Login</h1>
      <div>
        <label>
          <p>Username</p>
          <input type="text" ref={userNameRef} />
        </label>
      </div>
      <div>
        <label>
          <p>Password</p>
          <input type="password" ref={passwordRef} />
        </label>
      </div>
      <div>
        <button onClick={handleClick} className="cta cta--small cta--navy">
          <span>Submit</span>
        </button>
      </div>
    </div>
  );
};

export default App;
