import axios, { AxiosError } from "axios";
import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import Moment from "moment";
import {
  MemberSummary,
  Subscription,
  MemberBootcampSummary,
} from "../../interfaces/memberSummary";
import ProgressSummary from "../ProgressSummary";
import SubmittedQuestions from "../SubmittedQuestions";
import GrantModal from "../GrantModal";
import { SurveysV2 } from "./SurveysV2";
import RevokeModal from "../RevokeModal";

const QueryBox = styled.div`
  background-color: var(--bg-medium);
  border: 2px solid var(--pm-blue);
  margin: 1rem 0 0 0;
  padding: 1rem;
  text-align: center;

  input {
    margin: 0 1rem;
    width: 350px;
  }
`;

const WrapperBase = styled.div`
  border-bottom: 3px solid var(--pm-green);
  margin-bottom: 3rem;
  padding-bottom: 3rem;
`;

const MemberDetailsWrapper = styled.div`
  width: 100%;
`;

const MemberProfile = styled(WrapperBase)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: none;
  background-color: var(--pm-blue);
  color: white;
  padding: 1rem;
`;
const MemberProfileField = styled.div`
  display: flex;
`;

const MemberProfileLabel = styled.div`
  font-weight: bold;
  margin-right: 1rem;

  &.fixed {
    width: 250px;
  }
`;

const SurveyWrapper = styled(WrapperBase)``;
const QuestionsWrapper = styled(WrapperBase)``;
const SubscriptionsWrapper = styled(WrapperBase)``;

const MemberProfileData = styled.div``;

const Interior = styled.div`
  margin: 1rem 2rem;
`;

const ProgressSummaryWrapper = styled(WrapperBase)``;

export const UserDetails: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<MemberSummary | null>(null);
  const [memberBootcampSummaryData, setMemberBootcampSummaryData] = useState<
    MemberBootcampSummary[] | null
  >(null);
  const [message, setMessage] = useState<string>("");
  const [showGrant, setShowGrant] = useState(false);
  const [showRevoke, setShowRevoke] = useState(false);
  const { pmoId } = useParams();
  const [showUserNotFound, setShowUserNotFound] = useState(false);

  const handleClick = async () => {
    getData();
  };

  const handleShow = () => setShowUserNotFound(true);
  const handleClose = () => setShowUserNotFound(false);

  const getData = async () => {
    let url = null;

    let emailAddress = inputRef.current?.value.trim() || "";

    if (inputRef.current) {
      url = `${
        process.env.REACT_APP_NOT_SECRET_SERVICE_URL
      }/webapi/adminreporting/getmembersummary?emailAddress=${encodeURIComponent(
        emailAddress as string
      )}`;
    }

    if (pmoId) {
      url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getmembersummary?pmoId=${pmoId}`;
    }

    if (url) {
      try {
        const response = await axios({
          method: "Get",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          url: url,
        });

        if (response.status === 200) {
          if (
            (emailAddress &&
              response.data.EmailAddress.toLowerCase() !==
                emailAddress.toLowerCase()) ||
            emailAddress === "unknown@unknown.email"
          ) {
            handleShow();
          } else {
            setMessage("");
            setData(response.data);
            setMemberBootcampSummaryData(response.data.MemberBootcampSummaries);
          }
        } else {
          setMessage(response.statusText);
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          setMessage(`Subscriber not found`);
          setData(null);
          setMemberBootcampSummaryData(null);
        } else {
          setMessage(err.message);
        }
      }
    }
  };
  const grantClickHandler = () => {
    setShowGrant(!showGrant);
  };

  const revokeClickHandler = () => {
    setShowRevoke(!showRevoke);
  };

  const getCertificateHandler = (bootcampId: string, bootcampName: string) => {
    const downloadLink = `${
      process.env.REACT_APP_NOT_SECRET_SERVICE_URL
    }/webapi/adminreporting/getcertificate?
    }&bootcampId=${bootcampId.normalizeGuid()}&bootcampName=${encodeURIComponent(
      bootcampName as string
    )}&pmoId=${data?.pmoId}`;

    const link = document.createElement("a");
    link.href = downloadLink as string;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const performGrant = (numDays: number, bootcampId: string) => {
    setShowGrant(false);

    const url = `${
      process.env.REACT_APP_NOT_SECRET_SERVICE_URL
    }/webapi/adminreporting/grantuser?emailAddress=${
      data?.UserName
    }&grantDays=${numDays}&bootcampId=${bootcampId.normalizeGuid()}`;

    axios({
      method: "Post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    }).then((response) => {
      getData();
    });
  };

  const performRevoke = (bootcampId: string) => {
    setShowRevoke(false);

    const url = `${
      process.env.REACT_APP_NOT_SECRET_SERVICE_URL
    }/webapi/adminreporting/revokeuser?emailAddress=${
      data?.UserName
    }&bootcampId=${bootcampId.normalizeGuid()}`;

    axios({
      method: "Post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    }).then((response) => {
      getData();
    });
  };

  useEffect(() => {
    if (pmoId) {
      getData();
    }
  }, [pmoId]);

  const [idx, setIdx] = useState<number[]>([0]);

  return (
    <>
      <h1>User Details</h1>
      {showUserNotFound && (
        <Modal show={showUserNotFound} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p
              className="d-flex justify-content-center align-items-center"
              style={{
                height: "140px",
              }}
            >
              User not found
            </p>
          </Modal.Body>
        </Modal>
      )}
      {!pmoId && (
        <QueryBox>
          <label htmlFor="email-address">Email Address:</label>
          <input
            ref={inputRef}
            type="text"
            id="email-address"
            name="email_address"
          />
          <button className="cta cta--small cta--navy" onClick={handleClick}>
            <span>Go</span>
          </button>
        </QueryBox>
      )}
      {message && <p>{message}</p>}
      {data && (
        <MemberDetailsWrapper>
          <MemberProfile>
            <MemberProfileElement
              label="Name"
              value={`${data.FirstName} ${data.LastName}`}
            />
            <MemberProfileElement
              label="Profession Code"
              value={data.ProfessionCode}
            />
            <MemberProfileElement label="Party Id" value={data.partyId} />
            <MemberProfileElement label="Username" value={data.UserName} />
            <MemberProfileElement
              label="Location"
              value={`${data.City}, ${data.State}`}
            />
          </MemberProfile>

          <Accordion defaultActiveKey="0">
            {memberBootcampSummaryData &&
              memberBootcampSummaryData.map((memberBootcampSummary, index) => {
                return (
                  <Card key={index}>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={index.toString()}
                        onClick={() => {
                          if (idx.includes(index))
                            setIdx(idx.filter((i) => i !== index));
                          else setIdx([...idx, index]);
                        }}
                      >
                        <h2>
                          {memberBootcampSummary.BootcampName}{" "}
                          {idx.includes(index) ? (
                            <strong>-</strong>
                          ) : (
                            <strong>+</strong>
                          )}
                        </h2>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body>
                        <SubscriptionsWrapper>
                          <h3>Subscriptions</h3>
                          <Interior>
                            {memberBootcampSummary.Subscriptions.map(
                              (subscription) => {
                                return (
                                  <MemberSubscription
                                    {...subscription}
                                    key={subscription.Id}
                                  />
                                );
                              }
                            )}
                            <div className="d-flex">
                              <button
                                onClick={grantClickHandler}
                                className="cta cta--small cta--navy-border"
                              >
                                Grant Access
                              </button>
                              <div className="ml-5">
                                <button
                                  onClick={revokeClickHandler}
                                  className="cta cta--small cta--navy-border"
                                >
                                  Revoke Access
                                </button>
                              </div>
                            </div>

                            {showGrant && (
                              <GrantModal
                                emailAddress={data.UserName}
                                bootcampId={memberBootcampSummary.BootcampId}
                                onClose={grantClickHandler}
                                onGrant={performGrant}
                              />
                            )}

                            {showRevoke && (
                              <RevokeModal
                                emailAddress={data.UserName}
                                bootcampId={memberBootcampSummary.BootcampId}
                                onClose={revokeClickHandler}
                                onRevoke={performRevoke}
                              />
                            )}
                          </Interior>
                        </SubscriptionsWrapper>
                        <SubscriptionsWrapper>
                          <h3>Certification</h3>
                          <Interior>
                            <button
                              onClick={() =>
                                getCertificateHandler(
                                  memberBootcampSummary.BootcampId,
                                  memberBootcampSummary.BootcampName
                                )
                              }
                              className="cta cta--small cta--navy-border"
                            >
                              Download Certificate
                            </button>
                          </Interior>
                        </SubscriptionsWrapper>
                        {memberBootcampSummary.ProgressSummaries.filter(
                          (progressSummary) =>
                            progressSummary.BootcampId ===
                            memberBootcampSummary.BootcampId
                        ).map((matchingProgressSummary, index) => {
                          const creditSummary =
                            matchingProgressSummary.ProgressSummary.credit_summary.find(
                              (creditSummary) =>
                                creditSummary.bootcamp_id ===
                                memberBootcampSummary.BootcampId
                            );

                          const progress =
                            matchingProgressSummary.ProgressSummary.progress.find(
                              (p) =>
                                p.bootcamp_id ===
                                memberBootcampSummary.BootcampId
                            );

                          return (
                            creditSummary &&
                            progress && (
                              <ProgressSummaryWrapper key={index}>
                                <ProgressSummary
                                  name={matchingProgressSummary.BootcampName}
                                  progress={progress}
                                  creditSummary={creditSummary}
                                  questions={memberBootcampSummary.Questions}
                                />
                              </ProgressSummaryWrapper>
                            )
                          );
                        })}
                        <QuestionsWrapper>
                          <h3>Submitted Questions</h3>
                          <SubmittedQuestions
                            questionResults={memberBootcampSummary.Questions}
                          />
                        </QuestionsWrapper>
                        <SurveyWrapper>
                          <h3>Submitted Surveys</h3>
                          <SurveysV2
                            pmoId={pmoId}
                            bootcampId={memberBootcampSummary.BootcampId}
                            emailAddress={
                              inputRef.current?.value ? data.EmailAddress : ""
                            }
                          />
                        </SurveyWrapper>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
          </Accordion>
        </MemberDetailsWrapper>
      )}
    </>
  );
};

interface MemberProfileElementProps {
  fixedWidth?: boolean;
  label: string;
  value: string | number;
}

export const MemberProfileElement: React.FC<MemberProfileElementProps> = ({
  label,
  value,
  fixedWidth,
}) => {
  return (
    <MemberProfileField>
      <MemberProfileLabel className={fixedWidth ? "fixed" : "standard"}>
        {label}:
      </MemberProfileLabel>
      <MemberProfileData>{value}</MemberProfileData>
    </MemberProfileField>
  );
};

const SubscriptionItem = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

export const MemberSubscription: React.FC<Subscription> = (subscription) => {
  const stripeResponse = subscription.StripeResponse
    ? JSON.parse(subscription.StripeResponse)
    : null;
  const discounts = stripeResponse?.total_details?.breakdown?.discounts;
  const couponNames = discounts
    ?.map((discount: any) => discount?.discount?.coupon?.name)
    .join(", ");
  return (
    <SubscriptionItem>
      <MemberProfileElement
        fixedWidth={true}
        label="Subscription Initiated"
        value={Moment(subscription.Initiated).format("MM/DD/yyyy")}
      />
      {subscription.ValidUntil && (
        <MemberProfileElement
          fixedWidth={true}
          label="Valid Until"
          value={Moment(subscription.ValidUntil).format("MM/DD/yyyy")}
        />
      )}
      {subscription.Revoked && (
        <MemberProfileElement
          fixedWidth={true}
          label="Revoked"
          value={Moment(subscription.Revoked).format("MM/DD/yyyy")}
        />
      )}
      <MemberProfileElement
        fixedWidth={true}
        label="Payment Status"
        value={
          subscription.StripePaymentStatus
            ? subscription.StripePaymentStatus
            : "Cancelled / Failed"
        }
      />
      <MemberProfileElement
        fixedWidth={true}
        label="Stripe Session"
        value={subscription.StripePaymentSessionId}
      />
      {couponNames && (
        <MemberProfileElement
          fixedWidth={true}
          label="Promo Codes Used"
          value={couponNames}
        />
      )}
    </SubscriptionItem>
  );
};
