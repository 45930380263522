import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getQuestionId } from "../components/SubmittedQuestions";
import {
  CreditSummary,
  Progress,
  QuestionResult,
} from "../interfaces/memberSummary";
import {
  GetCourseProgressIcon,
  SecondsToMinutesAndSeconds,
  GetTotalTime,
} from "../utils/ProgressUtil";

const Wrapper = styled.section`
  margin: 2rem 0;
`;

const ProgressTable = styled.table`
  width: 100%;

  td,
  th {
    padding: 0.5rem 1rem;
  }

  thead,
  tfoot {
    background-color: #003678;
    color: white;
  }
`;

const StandardRow = styled.tr`
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

export interface MyProgressProps {
  name: string;
  progress: Progress;
  creditSummary: CreditSummary;
  questions: QuestionResult[];
}

const ProgressSummary: React.FC<MyProgressProps> = ({
  name,
  progress,
  creditSummary,
  questions,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("");
  const handleIconClick = (
    isAllCourses: boolean,
    partyId: number,
    courseId: string
  ) => {
    // this needs to be implemented as a separate feature because there are many issues stemming from manually marking courses as complete
    // setModalMessage(isAllCourses ? "Are you sure that you want to mark all courses completed?" : "Are you sure that you want to mark this course completed?");
    // setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleConfirmModal = () => {
    // Handle confirmation here
    setShowModal(false);
  };

  function creditSummaryHTML() {
    return { __html: creditSummary?.awards[0] };
  }

  const getQuestionCount = (courseId: string): number => {
    return questions.filter(
      (question) => question.SubmitQuestionData.CourseId === courseId
    ).length;
  };

  return (
    <Wrapper>
      <h3>Progress Summary - {name}</h3>
      <p dangerouslySetInnerHTML={creditSummaryHTML()}></p>
      <p>
        Completed {progress?.completed_courses} of {progress?.total_courses}{" "}
        courses
      </p>

      <ProgressTable>
        <thead>
          <tr>
            <th>Course Title</th>
            <th>Completed</th>
            <th title="time as mm:ss">Time Spent</th>
            <th title="length as mm:ss">Length</th>
            <th title="progress as mm:ss">Progress</th>
            <th title="credited time as mm:ss">Credited Time</th>
            <th>Questions</th>
          </tr>
        </thead>
        <tbody>
          {progress?.progress_records.map((record) => (
            <StandardRow key={record.id}>
              <td
                dangerouslySetInnerHTML={{ __html: record.course_title }}
              ></td>
              <td>{GetCourseProgressIcon(record)}</td>
              <td>{SecondsToMinutesAndSeconds(record.time)}</td>
              <td>{SecondsToMinutesAndSeconds(record.length)}</td>
              <td>{SecondsToMinutesAndSeconds(record.progress)}</td>
              <td>{SecondsToMinutesAndSeconds(record.credited_time)}</td>
              <td>
                {" "}
                {(() => {
                  const questionCount = getQuestionCount(record.course_id);
                  return questionCount > 0 ? (
                    <a href={"#" + getQuestionId(record.course_id)}>
                      {questionCount}
                    </a>
                  ) : (
                    <>{questionCount}</>
                  );
                })()}
              </td>
            </StandardRow>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total:</td>
            <td></td>
            <td>{GetTotalTime(progress?.progress_records, "time")}</td>
            <td>{GetTotalTime(progress?.progress_records, "length")}</td>
            <td>{GetTotalTime(progress?.progress_records, "progress")}</td>
            <td>{GetTotalTime(progress?.progress_records, "credited_time")}</td>
            <td>{questions.length}</td>
          </tr>
        </tfoot>
      </ProgressTable>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Completion</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmModal}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

export default ProgressSummary;
