import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Loading } from "../Loading";
import { BootcampSummary } from "../../interfaces/bootcampSummary";
import CourseUsers from "../CourseUsers";

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 5rem;
`;
export const Course: React.FC = () => { 
  const { bootcampId, courseId } = useParams();
  const [message, setMessage] = useState<string>("");
  const [bootcampSummary, setBootcampSummaryData] = useState<BootcampSummary | null>(null);    
  const [loading, setLoading] = useState<boolean>(false);
 
  const handleLoadData = () => {
    const url = `${process.env.REACT_APP_NOT_SECRET_CM_URL}/bootcampapi/bootcamp?bootcampId=${bootcampId}&sc_apikey=4B0DBFD0-D7B1-4FB4-8B72-D48EFEB66FDE`;
    setLoading(true);

    axios({
      method: "Get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage("");
          setBootcampSummaryData(response.data);          
        } else {
          setMessage(response.statusText);
        }
      })
      .catch((error) => {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          setMessage("Course not found");
          setBootcampSummaryData(null);
        } else {
          setMessage(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (bootcampId && courseId) {
      handleLoadData();
    }
  }, [bootcampId, courseId]);

  return (
    <>     
      {message && <p>{message}</p>}     
      {loading ? (
        <LoadingWrapper>
          <Loading />
          <br />
          <p>Loading...</p>
        </LoadingWrapper>
      ) : (
        bootcampSummary && <CourseUsers bootcampSummary={bootcampSummary}  courseId={courseId || ""}/>
      )}
      
    </>
  );
};
